import React from 'react'
import { useSelector } from 'react-redux'
import PageWrapper from '../../../components/PageWrapper'

function WomenWardrobeKupalniki() {
  const category = useSelector((state) => state.app.category)
  const filtersAll = useSelector((state) => state.filtersIt.filtersAll)
  const aksessuary = filtersAll.filter((item) => item.type === 10)
  const brands = filtersAll.filter((item) => item.type === 'accessoriesBrand')
  const size = filtersAll.filter((item) => item.type === 'sizeProduct')
  const stateProduct = filtersAll.filter((item) => item.type === 'stateProduct')
  return (
    <PageWrapper
      isLinks={true}
      filtersAll={filtersAll}
      isFiltersAll={true}
      categoryRoute={null}
      columnRoute={'zhenskaya-odezhda'}
      subCategoryRoute={'kupalniki'}
      category={category}
      categoryId={1}
      columnsId={1}
      subCategoryId={10}
      isAksessuary={true}
      aksessuary={aksessuary}
      brands={brands}
      isBrands={true}
      title={'женская одежда'}
      subTitle={'Купальники'}
      size={size}
      isSize={true}
      isStateProduct={true}
      stateProduct={stateProduct}
    />
  )
}
export default WomenWardrobeKupalniki
