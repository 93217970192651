import React from 'react'

function ProductBuy() {
  return (
    <>
      <></>
    </>
  )
}
export default ProductBuy
