const routesLik = Object.freeze({
  root: '/',
  login: '/login',
  register: '/register',
  productCreate: '/product/create',
  productBuy: '/product/buy/:id',
  messages: '/messages',
  messagesId: '/messages/:id',
  womenWardrobe: '/zhenskaya-odezhda',
  womenWardrobeA: '/zhenskaya-odezhda/aksessuary',
  womenWardrobeAId: '/zhenskaya-odezhda/aksessuary/:id',
  womenWardrobeBluzy: '/zhenskaya-odezhda/bluzy-rubashki',
  womenWardrobeBluzyId: '/zhenskaya-odezhda/bluzy-rubashki/:id',
  womenWardrobeDlyaBeremennyh: '/zhenskaya-odezhda/dlya-beremennyh',
  womenWardrobeDlyaBeremennyhId: '/zhenskaya-odezhda/dlya-beremennyh/:id',
  womenWardrobeVerhnyaya: '/zhenskaya-odezhda/verhnyaya',
  womenWardrobeVerhnyayaId: '/zhenskaya-odezhda/verhnyaya/:id',
  womenWardrobeGolovnyeUbory: '/zhenskaya-odezhda/golovnye-ubory',
  womenWardrobeGolovnyeUboryId: '/zhenskaya-odezhda/golovnye-ubory/:id',
  womenWardrobeDomashnyaya: '/zhenskaya-odezhda/domashnyaya',
  womenWardrobeDomashnyayaId: '/zhenskaya-odezhda/domashnyaya/:id',
  womenWardrobeKombinezony: '/zhenskaya-odezhda/kombinezony',
  womenWardrobeKombinezonyId: '/zhenskaya-odezhda/kombinezony/:id',
  womenWardrobeKupalniki: '/zhenskaya-odezhda/kupalniki',
  womenWardrobeKupalnikiId: '/zhenskaya-odezhda/kupalniki/:id',
  womenWardrobeBeleKupalniki: '/zhenskaya-odezhda/bele-kupalniki',
  womenWardrobeBeleKupalnikiId: '/zhenskaya-odezhda/bele-kupalniki/:id',
  womenWardrobeObuv: '/zhenskaya-odezhda/obuv',
  womenWardrobeObuvId: '/zhenskaya-odezhda/obuv/:id',
  womenWardrobePidzhakiKostyumy: '/zhenskaya-odezhda/pidzhaki-kostyumy',
  womenWardrobePidzhakiKostyumyId: '/zhenskaya-odezhda/pidzhaki-kostyumy/:id',
  womenWardrobePlatyaYubki: '/zhenskaya-odezhda/platya-yubki',
  womenWardrobePlatyaYubkiId: '/zhenskaya-odezhda/platya-yubki/:id',
  womenWardrobeSviteryTolstovki: '/zhenskaya-odezhda/svitery-tolstovki',
  womenWardrobeSviteryTolstovkiId: '/zhenskaya-odezhda/svitery-tolstovki/:id',
  womenWardrobeSportivnaya: '/zhenskaya-odezhda/sportivnaya',
  womenWardrobeSportivnayaId: '/zhenskaya-odezhda/sportivnaya/:id',
  womenWardrobeFutbolkiTopy: '/zhenskaya-odezhda/futbolki-topy',
  womenWardrobeFutbolkiTopyId: '/zhenskaya-odezhda/futbolki-topy/:id',
  womenWardrobeDzhinsyBryuki: '/zhenskaya-odezhda/dzhinsy-bryuki',
  womenWardrobeDzhinsyBryukiId: '/zhenskaya-odezhda/dzhinsy-bryuki/:id',
  womenWardrobeDrugoe: '/zhenskaya-odezhda/drugoe',
  womenWardrobeDrugoeId: '/zhenskaya-odezhda/drugoe/:id',
  //menWardrobe
  menWardrobe: '/muzhskaya-odezhda',
  menWardrobeAksessuary: '/muzhskaya-odezhda/aksessuary',
  menWardrobeAksessuaryId: '/muzhskaya-odezhda/aksessuary/:id',
  menWardrobeVerhnyaya: '/muzhskaya-odezhda/verhnyaya',
  menWardrobeVerhnyayaId: '/muzhskaya-odezhda/verhnyaya/:id',
  menWardrobeGolovnyeUbory: '/muzhskaya-odezhda/golovnye-ubory',
  menWardrobeGolovnyeUboryId: '/muzhskaya-odezhda/golovnye-ubory/:id',
  menWardrobeDomashnyaya: '/muzhskaya-odezhda/domashnyaya',
  menWardrobeDomashnyayaId: '/muzhskaya-odezhda/domashnyaya/:id',
  menWardrobeKombinezony: '/muzhskaya-odezhda/kombinezony',
  menWardrobeKombinezonyId: '/muzhskaya-odezhda/kombinezony/:id',
  menWardrobenNizhneeBelePlavki: '/muzhskaya-odezhda/nizhnee-bele-plavki',
  menWardrobeNizhneeBelePlavkiId: '/muzhskaya-odezhda/nizhnee-bele-plavki/:id',
  menWardrobeObuv: '/muzhskaya-odezhda/obuv',
  menWardrobeObuvId: '/muzhskaya-odezhda/obuv/:id',
  menWardrobePidzhakiKostyumy: '/muzhskaya-odezhda/pidzhaki-kostyumy',
  menWardrobePidzhakiKostyumyId: '/muzhskaya-odezhda/pidzhaki-kostyumy/:id',
  menWardrobeRubashki: '/muzhskaya-odezhda/rubashki',
  menWardrobeRubashkiId: '/muzhskaya-odezhda/rubashki/:id',
  menWardrobeSviteryTolstovki: '/muzhskaya-odezhda/svitery-tolstovki',
  menWardrobeSviteryTolstovkiId: '/muzhskaya-odezhda/svitery-tolstovki/:id',
  menWardrobeSpecodezhda: '/muzhskaya-odezhda/specodezhda',
  menWardrobeSpecodezhdaId: '/muzhskaya-odezhda/specodezhda/:id',
  menWardrobeSportivnaya: '/muzhskaya-odezhda/sportivnaya',
  menWardrobeSportivnayaId: '/muzhskaya-odezhda/sportivnaya/:id',
  menWardrobeFutbolkiPolo: '/muzhskaya-odezhda/futbolki-polo',
  menWardrobeFutbolkiPoloId: '/muzhskaya-odezhda/futbolki-polo/:id',
  menWardrobeDzhinsyBryuki: '/muzhskaya-odezhda/dzhinsy-bryuki',
  menWardrobeDzhinsyBryukiId: '/muzhskaya-odezhda/dzhinsy-bryuki/:id',
  menWardrobeDrugoe: '/muzhskaya-odezhda/drugoe',
  menWardrobeDrugoeId: '/muzhskaya-odezhda/drugoe/:id',
  //childrenWardrobe
  childrenWardrobe: '/detskaya-odezhda',
  childrenWardrobeAksessuary: '/detskaya-odezhda/aksessuary',
  childrenWardrobeAksessuaryId: '/detskaya-odezhda/aksessuary/:id',
  childrenWardrobeBluzyIRubashki: '/detskaya-odezhda/bluzy-i-rubashki',
  childrenWardrobeBluzyIRubashkiId: '/detskaya-odezhda/bluzy-i-rubashki/:id',
  //
  uslugi: '/uslugi',
  obuchenie: '/uslugi/obuchenie',
})
export default routesLik
