import React from 'react'
import { useSelector } from 'react-redux'
import PageWrapper from '../../../components/PageWrapper'

function MenWardrobeAksessuary() {
  const category = useSelector((state) => state.app.category)
  const filtersAll = useSelector((state) => state.filtersIt.filtersAll)
  const aksessuary = filtersAll.filter((item) => item.type === 2)
  const brands = filtersAll.filter((item) => item.type === 'accessoriesBrand')
  return (
    <PageWrapper
      isLinks={true}
      filtersAll={filtersAll}
      isFiltersAll={true}
      categoryRoute={null}
      columnRoute={'muzhskaya-odezhda'}
      subCategoryRoute={'aksessuary'}
      category={category}
      categoryId={1}
      columnsId={2}
      subCategoryId={2}
      isAksessuary={true}
      aksessuary={aksessuary}
      brands={brands}
      isBrands={true}
      title={'Мужские аксессуары'}
      subTitle={'Мужской гардероб'}
    />
  )
}
export default MenWardrobeAksessuary
