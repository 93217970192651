import React from 'react'
import PageWrapper from '../../../components/PageWrapper'
import { useSelector } from 'react-redux'

function WomenWardrobeBluzyRubashki() {
  const category = useSelector((state) => state.app.category)
  const filtersAll = useSelector((state) => state.filtersIt.filtersAll)
  const aksessuary = filtersAll.filter((item) => item.type === 4)
  const brands = filtersAll.filter((item) => item.type === 'accessoriesBrand')
  const size = filtersAll.filter((item) => item.type === 'sizeProduct')
  const stateProduct = filtersAll.filter((item) => item.type === 'stateProduct')
  return (
    <PageWrapper
      isLinks={true}
      filtersAll={filtersAll}
      isFiltersAll={true}
      categoryRoute={null}
      columnRoute={'zhenskaya-odezhda'}
      subCategoryRoute={'bluzy-rubashki'}
      category={category}
      categoryId={1}
      columnsId={1}
      subCategoryId={4}
      isAksessuary={true}
      aksessuary={aksessuary}
      brands={brands}
      isBrands={true}
      title={'Вещи, электроника и прочее'}
      subTitle={'женская одежда'}
      size={size}
      isSize={true}
      isStateProduct={true}
      stateProduct={stateProduct}
    />
  )
}
export default WomenWardrobeBluzyRubashki
