import React, { useState } from 'react'
import styles from './index.module.scss'
import * as actions from '../../actions'
import { Rating } from 'react-simple-star-rating'
import { getAvatarUrl } from '../../api'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'

function ModalViewNumber({ dispatch, user, product }) {
  const [rating, setRating] = useState(0)
  const handleRating = (rate) => {
    setRating(rate)
  }
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value, index) => console.log(value, index)

  return (
    <div className={styles.ModalViewNumber}>
      <div className={styles.modalContainer}>
        <div className={styles.modalInner}>
          <div
            className={styles.closeContainer}
            onClick={() => {
              dispatch(actions.isModalMobilePhone(null))
            }}
          >
            <i className={styles.closeIcon}></i>
          </div>
          <div className={styles.modalBoxInner}>
            <p className={styles.userNameIn}>
              {user.firstName} {user.lastName}
            </p>
            <figure className={styles.userFigure}>
              <Link
                to={`/user/${user.id}`}
                className={styles.linkTouser}
                onClick={() => {
                  dispatch(actions.isModalMobilePhone(null))
                }}
              >
                <img
                  src={getAvatarUrl(user.avatar)}
                  width={72}
                  height={72}
                  alt="Елена С."
                  className={styles.avatar}
                />
              </Link>
              <div className={styles.infoUser}>
                <p className={styles.userCountry}></p>
                <p className={styles.userCountry}>
                  на&nbsp;Atuta&nbsp;с&nbsp;
                  {dayjs(user.createdAt).format('D MMM YYYY')}
                </p>
                <div className={styles.ratingContainer}>
                  <Rating
                    iconsCount={5}
                    onClick={handleRating}
                    onPointerEnter={onPointerEnter}
                    onPointerLeave={onPointerLeave}
                    onPointerMove={onPointerMove}
                    initialValue={rating}
                  />
                </div>
              </div>
            </figure>
            <hr className={styles.border} />
            <div className={styles.numberBox}>
              <p className={styles.userPhone}>{user.phone}</p>
              <p className={styles.info}>
                Вы можете связаться с продавцом через личные сообщения
              </p>
            </div>
            <Link
              className={styles.buttonAction}
              to={`/message/${user.id}`}
              onClick={() => {
                dispatch(actions.isModalMobilePhone(null))
                dispatch(actions.chatId(product))
              }}
            >
              <span className={styles.label}>Написать сообщение</span>
            </Link>
            <button
              className={styles.buttonAction}
              onClick={() => {
                dispatch(actions.isModalMobilePhone(null))
              }}
            >
              <span className={styles.label}>Позвонить онлайн</span>
            </button>
            <button
              className={styles.buttonAction}
              onClick={() => {
                dispatch(actions.isModalMobilePhone(null))
              }}
            >
              <span className={styles.label}>Пожаловаться на объявление</span>
            </button>
          </div>
        </div>
      </div>
      <div
        className={styles.modalBg}
        onClick={() => {
          dispatch(actions.isModalMobilePhone(null))
        }}
      />
    </div>
  )
}

export default ModalViewNumber
