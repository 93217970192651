import React from 'react'
import { useSelector } from 'react-redux'
import PageWrapper from '../../../components/PageWrapper'

function WomenWardrobeDrugoe() {
  const category = useSelector((state) => state.app.category)
  return (
    <PageWrapper
      isLinks={true}
      categoryRoute={null}
      columnRoute={'zhenskaya-odezhda'}
      subCategoryRoute={'drugoe'}
      category={category}
      categoryId={1}
      columnsId={1}
      subCategoryId={19}
      title={'женская одежда'}
      subTitle={'Штаны и шорты'}
    />
  )
}
export default WomenWardrobeDrugoe
