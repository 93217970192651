import React, { useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import PageContainer from '../PageContainer'
import styles from './index.module.scss'
import IsActiveSubCategoryList from '../IsActiveSubCategoryList'
import IsActiveBrandList from '../IsActiveBrandList'

function PageWrapper({
  title = 'Atuta',
  subTitle = null,
  isFiltersAll = false,
  filtersAll = [],
  categoryRoute = null,
  columnRoute = null,
  subCategoryRoute = null,
  category = [],
  categoryId = null,
  columnsId = null,
  isSubCategory = false,
  subCategoryId = null,
  isLinks = false,
  categoryFind = [],
  isBrands = false,
  brands = [],
  isAksessuary = false,
  aksessuary = [],
  isSize = false,
  size = [],
  isStateProduct = false,
  stateProduct = [],
  isSeason = false,
  season = [],
  isLengthProduct = false,
  lengthProduct = [],
  isSex = false,
  sex = [],
}) {
  const [isActiveSubCategory, setIsActiveSubCategory] = useState(false)
  const [isActiveBrand, setIsActiveBrand] = useState(false)
  const [isPrice, setIsPrice] = useState(true)
  const [isActiveAksessuary, setIsActiveAksessuary] = useState(false)
  const [isActiveColor, setIsActiveColor] = useState(false)
  const [isSizeActive, setIsSizeActive] = useState(false)
  const [isStateProductActive, setIsStateProductActive] = useState(false)
  const [isSeasonActive, setIsSeasonActive] = useState(false)
  const [isLengthProductActive, setIsLengthProductActive] = useState(false)
  const [isSexActive, setIsSexActive] = useState(false)
  const color = isFiltersAll
    ? filtersAll.filter((item) => item.type === 'colorProduct')
    : false
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          {title} {subTitle ? subTitle : ''}
        </title>
      </Helmet>
      <PageContainer
        categoryRoute={categoryRoute}
        columnRoute={columnRoute}
        subCategoryRoute={subCategoryRoute}
        brands={brands}
        isLinks={isLinks}
        category={category}
        categoryId={categoryId}
        columnsId={columnsId}
        subCategoryId={subCategoryId}
      >
        {isSubCategory && (
          <div
            className={styles.subCategoryF}
            onClick={() => {
              setIsActiveSubCategory(!isActiveSubCategory)
            }}
          >
            <h2 className={styles.subCategoryTitles}>Уточнить подкатегорию</h2>
            {isActiveSubCategory ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className={styles.subCategoryarrov}
              >
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            )}
          </div>
        )}
        {isActiveSubCategory && categoryFind && categoryFind.length > 0 && (
          <>
            {categoryFind.map((item, index) => (
              <IsActiveSubCategoryList
                subCategory={item}
                key={index}
                categoryRoute={null}
                columnRoute={columnRoute}
              />
            ))}
          </>
        )}
        {isAksessuary && (
          <div
            className={styles.subCategoryF}
            onClick={() => {
              setIsActiveAksessuary(!isActiveAksessuary)
            }}
          >
            <h2 className={styles.subCategoryTitles}>Тип</h2>
            {isActiveAksessuary ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className={styles.subCategoryarrov}
              >
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            )}
          </div>
        )}
        {isActiveAksessuary && (
          <div className={styles.brandList}>
            {aksessuary.length > 0 && (
              <>
                {aksessuary.map((item, index) => (
                  <IsActiveBrandList brand={item} key={index} />
                ))}
              </>
            )}
          </div>
        )}
        {isBrands && (
          <div
            className={styles.subCategoryF}
            onClick={() => {
              setIsActiveBrand(!isActiveBrand)
            }}
          >
            <h2 className={styles.subCategoryTitles}>Бренд</h2>
            {isActiveBrand ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className={styles.subCategoryarrov}
              >
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            )}
          </div>
        )}
        {isActiveBrand && brands && brands.length > 0 && (
          <div className={styles.brandList}>
            {brands.map((item, index) => (
              <IsActiveBrandList brand={item} key={index} />
            ))}
          </div>
        )}
        {isSize && (
          <div
            className={styles.subCategoryF}
            onClick={() => {
              setIsSizeActive(!isSizeActive)
            }}
          >
            <h2 className={styles.subCategoryTitles}>Размеры</h2>
            {isSizeActive ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className={styles.subCategoryarrov}
              >
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            )}
          </div>
        )}
        {isSizeActive && size && size.length > 0 && (
          <div className={styles.brandList}>
            {size.map((item, index) => (
              <IsActiveBrandList brand={item} key={index} />
            ))}
          </div>
        )}
        {isLengthProduct && (
          <div
            className={styles.subCategoryF}
            onClick={() => {
              setIsLengthProductActive(!isLengthProductActive)
            }}
          >
            <h2 className={styles.subCategoryTitles}>Длина</h2>
            {isLengthProductActive ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className={styles.subCategoryarrov}
              >
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            )}
          </div>
        )}
        {isLengthProductActive && lengthProduct && lengthProduct.length > 0 && (
          <div className={styles.brandList}>
            {lengthProduct.map((item, index) => (
              <IsActiveBrandList brand={item} key={index} />
            ))}
          </div>
        )}
        {isSex && (
          <div
            className={styles.subCategoryF}
            onClick={() => {
              setIsSexActive(!isSexActive)
            }}
          >
            <h2 className={styles.subCategoryTitles}>Пол</h2>
            {isSexActive ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className={styles.subCategoryarrov}
              >
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            )}
          </div>
        )}
        {isSexActive && sex && sex.length > 0 && (
          <div className={styles.brandList}>
            {sex.map((item, index) => (
              <IsActiveBrandList brand={item} key={index} />
            ))}
          </div>
        )}
        {/**/}
        {isFiltersAll && color && (
          <div
            className={styles.subCategoryF}
            onClick={() => {
              setIsActiveColor(!isActiveColor)
            }}
          >
            <h2 className={styles.subCategoryTitles}>Цвет</h2>
            {isActiveColor ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className={styles.subCategoryarrov}
              >
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            )}
          </div>
        )}
        {isActiveColor && color && (
          <div className={styles.brandList}>
            {color.length > 0 && (
              <>
                {color.map((item, index) => (
                  <IsActiveBrandList brand={item} key={index} />
                ))}
              </>
            )}
          </div>
        )}
        {isSeason && (
          <div
            className={styles.subCategoryF}
            onClick={() => {
              setIsSeasonActive(!isSeasonActive)
            }}
          >
            <h2 className={styles.subCategoryTitles}>Сезон</h2>
            {isSeasonActive ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className={styles.subCategoryarrov}
              >
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            )}
          </div>
        )}
        {isSeasonActive && season && season.length > 0 && (
          <div className={styles.brandList}>
            {season.map((item, index) => (
              <IsActiveBrandList brand={item} key={index} />
            ))}
          </div>
        )}
        {isStateProduct && (
          <div
            className={styles.subCategoryF}
            onClick={() => {
              setIsStateProductActive(!isStateProductActive)
            }}
          >
            <h2 className={styles.subCategoryTitles}>Состояние товара</h2>
            {isStateProductActive ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className={styles.subCategoryarrov}
              >
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            ) : (
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                  d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                  fill="#333"
                  fillRule="evenodd"
                ></path>
              </svg>
            )}
          </div>
        )}
        {isStateProductActive && stateProduct && stateProduct.length > 0 && (
          <div className={styles.brandList}>
            {stateProduct.map((item, index) => (
              <IsActiveBrandList brand={item} key={index} />
            ))}
          </div>
        )}
        {/**/}
        <div
          className={styles.subCategoryF}
          onClick={() => {
            setIsPrice(!isPrice)
          }}
        >
          <h2 className={styles.subCategoryTitles}>Цена, ₽</h2>
          {isPrice ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className={styles.subCategoryarrov}
            >
              <path
                d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                fill="#333"
                fillRule="evenodd"
              ></path>
            </svg>
          ) : (
            <svg width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M11.986 13.49l4.236-4.512.729.685-4.965 5.288-4.964-5.288.729-.685z"
                fill="#333"
                fillRule="evenodd"
              ></path>
            </svg>
          )}
        </div>
        {isPrice && (
          <div className={styles.isPriceContainer}>
            <div className={styles.inputSerchPriceContainer}>
              <div className={styles.inputSerchPriceInner}>
                <div className={styles.inputSerchPriceWrapper}>
                  <div>от&nbsp;</div>
                  <input className={styles.inputSerchPrice1} />
                </div>
              </div>
            </div>
            <div className={styles.inputSerchPriceContainer}>
              <div className={styles.inputSerchPriceInner2}>
                <div className={styles.inputSerchPriceWrapper}>
                  <div>до&nbsp;</div>
                  <input className={styles.inputSerchPrice1} />
                </div>
              </div>
            </div>
          </div>
        )}
      </PageContainer>
    </HelmetProvider>
  )
}

export default PageWrapper
