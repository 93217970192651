import React from 'react'
import { useSelector } from 'react-redux'
import PageWrapper from '../../../components/PageWrapper'

function ChildrenWardrobe() {
  const category = useSelector((state) => state.app.category)
  const filtersAll = useSelector((state) => state.filtersIt.filtersAll)
  const categoryFind = category
    .find((c) => c.id === 1)
    .columns.find((c) => c.id === 3).subCategory
  const brands = filtersAll.filter((item) => item.type === 'accessoriesBrand')
  return (
    <PageWrapper
      isLinks={true}
      categoryRoute={null}
      columnRoute={'detskaya-odezhda'}
      subCategoryRoute={null}
      category={category}
      categoryFind={categoryFind}
      isBrands={true}
      isSubCategory={true}
      brands={brands}
      categoryId={1}
      columnsId={3}
      title={'Детский гардероб'}
      isFiltersAll={true}
      filtersAll={filtersAll}
    />
  )
}
export default ChildrenWardrobe
