import React from 'react'
import { useSelector } from 'react-redux'
import PageWrapper from '../../../components/PageWrapper'

function MenWardrobeDrugoe() {
  const category = useSelector((state) => state.app.category)
  return (
    <PageWrapper
      isLinks={true}
      categoryRoute={null}
      columnRoute={'muzhskaya-odezhda'}
      subCategoryRoute={'drugoe'}
      category={category}
      categoryId={1}
      columnsId={2}
      subCategoryId={33}
      title={'Другое'}
      subTitle={'Мужской гардероб'}
    />
  )
}
export default MenWardrobeDrugoe
